body {
    margin: 0;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
}

.mapboxgl-ctrl-group button.-active {
    background-color: lightgray;
}

.mapboxgl-ctrl-group button.active {
    background-color: lightgray;
}

.App,
#map,
#root,
body,
html,
.main-view {
    height: 100%;
    width: 100%;
}

.noselect {
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    /*for different browsers*/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.menu {
    transition: all 0.4s;
}

.feature-info-table {
    table-layout: fixed;
    width: 100%;
    border-spacing: 0px;
    table-layout: auto !important;
}

.feature-info-table tr {
    font-size: 12px;
}

.feature-info-table th {
    padding: 16px;
    border-bottom: whitesmoke 1px solid;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 5px;
}

.feature-info-table th div {
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}

.autocomplete-icon {
    font-size: 14px !important;
    margin-right: 8px;
}

.container {
    display: grid;
    grid-template-columns: min-content min-content minmax(20%, 100%);
    grid-template-areas: "toolbar sidebar map";
}

.sidebar {
    grid-area: sidebar;
    background-color: white;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.grow {
    flex-grow: 1;
}

.title {
    display: flex;
    align-items: center;
    padding: 16px;
}

.sidebar.hide {
    display: none;
}

.toolbar {
    grid-area: toolbar;
    width: 64px;
    display: flex;
    flex-flow: column;
    height: 100%;
}

#map {
    grid-area: map;
}

.mapboxgl-popup-close-button {
    position: absolute;
    right: 8px;
    top: 8px;
    border: 0;
    border-radius: 0 3px 0 0;
    cursor: pointer;
    background-color: transparent;
    font-size: 222px;
    width: 48px;
    height: 44px;
    font-size: 32px;
    z-index: 10;
    color: white;
}
.mapboxgl-popup-close-button:hover {
    background-color: #024264;
}

.mapboxgl-popup-content {
    padding: 0px;
    overflow: auto;
    display: flex;
    max-height: 400px;
}

.mapboxgl-popup-content > div {
    overflow: auto;
    display: flex;
}

.mapboxgl-ctrl-icon {
    padding: 0px;
}

.mapboxgl-ctrl-bottom-right{
    margin-bottom: 32px;
}

.ctrl-disclaimer{
    padding: 4px;
    color: black;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 4;
    background-color: white;
    font-size: 12px;
}


.ctrl-disclaimer a{
    cursor: pointer;
    color: black;
}

.ctrl-disclaimer a:hover{
color: rgb(44, 44, 44);
text-decoration: underline;
}

::-webkit-scrollbar-track {
    background-color: #f5f4f4;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: lightgray;
}

.mapboxgl-ctrl-logo {
    display: none;
}

div[role="tooltip"] {
    z-index: 11;
}

@media only screen and (max-width: 600px) {
    .container {
        display: grid;
        grid-template-columns: min-content minmax(20%, 100%);
        grid-template-areas: "toolbar map";
    }

    .sidebar {
        grid-area: map;
        z-index: 2;
        width: 100%;
        min-width: 0;
    }
}

.mapboxgl-ctrl-logo {
    /* display: none !important; */
}

.mapboxgl-ctrl-pitchtoggle-3d {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjNEPC90ZXh0Pjwvc3ZnPg==);
}

.mapboxgl-ctrl-pitchtoggle-2d {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjJEPC90ZXh0Pjwvc3ZnPg==);
}